import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import ActivatedHumanLanding from './components/Landing';
import LifeFrameworkHome from './components/life-framework/Home';
import LinguaBoostHome from './components/language-learner/Home';
import WelcomePage from './components/WelcomePage';

import About from './components/company/About';
import Contact from './components/company/Contact';
import Terms from './components/company/Terms';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
      <Router>
        <AppContent />
      </Router>
  );
}

function AppContent() {
  return (
    <div className="App font-sans text-gray-900">
      <Header />
      <Routes>
        <Route path="/" element={<ActivatedHumanLanding />} />
        <Route path="/life-framework" element={<LifeFrameworkHome />} />
        <Route path="/linguaboost" element={<LinguaBoostHome />} />

        <Route path="/welcome" element={<WelcomePage />} />
        
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;