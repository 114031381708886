import React from 'react';

function About() {
    return (
      <section className="py-16">
        <div className="container mx-auto text-center px-4 sm:px-6 md:px-8">
          <h2 className="text-3xl font-semibold mb-8">About Us</h2>
          <p className="text-lg leading-relaxed">
            Activated Human offers innovative AI-driven software tools designed to help users maximize their well-being, 
            align their goals and energy, and enhance their overall lifestyle. Our mission is to empower individuals to 
            achieve their highest potential through personalized and intuitive technology solutions.
          </p>
        </div>
      </section>
    );
  }

export default About;