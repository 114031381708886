import React from 'react';

function Terms() {
    return (
      <section className="py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-8">Terms of Service</h2>
          <div className="text-left max-w-prose mx-auto">
            <h3 className="text-xl font-semibold">Return Policy</h3>
            <p>We offer a 7-day trial period for all our software tools. If you are not satisfied within this period, you can request cancellation of your subscription by contacting our customer service team.</p>
  
            <h3 className="text-xl font-semibold mt-4">Refund and Dispute Policy</h3>
            <p>If you are unsatisfied with our software for any reason, please contact us for a resolution. Refunds are processed on a case-by-case basis.</p>
  
            <h3 className="text-xl font-semibold mt-4">Cancellation Policy</h3>
            <p>You can cancel your subscription at any time through your account settings or by contacting customer service. No further charges will be applied after cancellation.</p>
  
            <h3 className="text-xl font-semibold mt-4">Terms and Conditions</h3>
            <p>All transactions are processed in USD. Promotional offers are valid for new customers only and cannot be combined with other offers.</p>
  
            <h3 className="text-xl font-semibold mt-4">Security Capabilities and Policy</h3>
            <p>We use SSL encryption to protect your payment card details during transmission. Your data is stored securely and is only accessible to authorized personnel.</p>
  
            <h3 className="text-xl font-semibold mt-4">Consumer Data Privacy Policy</h3>
            <p>We are committed to protecting your privacy. Your personal information is used solely for providing and improving our services. We do not share your information with third parties without your consent.</p>
          </div>
        </div>
      </section>
    );
  }

  export default Terms;
  