import React from "react";
import { motion } from 'framer-motion';


const Hero = () => (
    <section className="relative bg-gray-900 text-white py-20 overflow-hidden">
      <div className="container mx-auto px-4 text-center relative z-10">
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-5xl md:text-6xl font-bold mb-6"
        >
          Power Smarter Personal Growth
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-xl md:text-2xl mb-8 max-w-3xl mx-auto text-gray-300"
        >
          Discover AI-driven tools designed to elevate your life and accelerate your learning journey.
        </motion.p>
        {/* <motion.button
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="bg-coral-500 hover:bg-coral-600 text-white px-8 py-3 rounded-full text-lg font-semibold transition duration-300"
          onClick={() => document.getElementById('products').scrollIntoView({ behavior: 'smooth' })}
        >
          Explore Our Products
        </motion.button> */}
        <motion.button
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="bg-white text-blue-900 px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300"
        onClick={() => document.getElementById('products').scrollIntoView({ behavior: 'smooth' })}
      >
        Explore Our Products
      </motion.button>
      </div>
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-to-br from-purple-900 to-blue-900 opacity-50"></div>
        <svg className="absolute inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="hero-pattern" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
              <circle cx="20" cy="20" r="1" fill="white" fillOpacity="0.1" />
              <circle cx="0" cy="0" r="1" fill="white" fillOpacity="0.1" />
              <circle cx="0" cy="40" r="1" fill="white" fillOpacity="0.1" />
              <circle cx="40" cy="0" r="1" fill="white" fillOpacity="0.1" />
              <circle cx="40" cy="40" r="1" fill="white" fillOpacity="0.1" />
            </pattern>
          </defs>
          <rect x="0" y="0" width="100%" height="100%" fill="url(#hero-pattern)" />
        </svg>
      </div>
    </section>
  );


  // const Hero = () => (
  //   <section className="bg-gradient-to-br from-blue-900 to-purple-700 text-white py-20">
  //     <div className="container mx-auto px-4 text-center">
  //       <motion.h1 
  //         initial={{ opacity: 0, y: 20 }}
  //         animate={{ opacity: 1, y: 0 }}
  //         transition={{ duration: 0.5 }}
  //         className="text-5xl md:text-6xl font-bold mb-6"
  //       >
  //         Elevate Your Life with AI-Driven Solutions
  //       </motion.h1>
  //       <motion.p
  //         initial={{ opacity: 0, y: 20 }}
  //         animate={{ opacity: 1, y: 0 }}
  //         transition={{ duration: 0.5, delay: 0.2 }}
  //         className="text-xl md:text-2xl mb-8 max-w-3xl mx-auto"
  //       >
  //         Discover innovative tools designed to enhance your personal growth and language learning journey.
  //       </motion.p>
  //       <motion.button
  //         initial={{ opacity: 0, y: 20 }}
  //         animate={{ opacity: 1, y: 0 }}
  //         transition={{ duration: 0.5, delay: 0.4 }}
  //         className="bg-white text-blue-900 px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300"
  //       >
  //         Explore Our Products
  //       </motion.button>
  //     </div>
  //   </section>
  // );
  




// const Hero = () => (
//   <section className="relative bg-[#1e1e2e] text-white py-20 overflow-hidden">
//     <div className="container mx-auto px-4 text-center relative z-10">
//       <motion.h1 
//         initial={{ opacity: 0, y: 20 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.5 }}
//         className="text-5xl md:text-6xl font-bold mb-6"
//       >
//         Power Smarter Personal Growth
//       </motion.h1>
//       <motion.p
//         initial={{ opacity: 0, y: 20 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.5, delay: 0.2 }}
//         className="text-xl md:text-2xl mb-8 max-w-3xl mx-auto text-gray-300"
//       >
//         Discover AI-driven tools designed to elevate your life and accelerate your learning journey.
//       </motion.p>
//       <motion.button
//         initial={{ opacity: 0, y: 20 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.5, delay: 0.4 }}
//         className="bg-[#8b5cf6] hover:bg-[#7c3aed] text-white px-8 py-3 rounded-full text-lg font-semibold transition duration-300"
//         onClick={() => document.getElementById('products').scrollIntoView({ behavior: 'smooth' })}
//       >
//         Explore Our Products
//       </motion.button>
//     </div>
//     <div className="absolute inset-0 z-0 opacity-10">
//       <svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg">
//         <defs>
//           <pattern id="hero-pattern" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
//             <circle cx="20" cy="20" r="1" fill="white" />
//             <circle cx="0" cy="0" r="1" fill="white" />
//             <circle cx="40" cy="0" r="1" fill="white" />
//             <circle cx="0" cy="40" r="1" fill="white" />
//             <circle cx="40" cy="40" r="1" fill="white" />
//           </pattern>
//         </defs>
//         <rect x="0" y="0" width="100%" height="100%" fill="url(#hero-pattern)" />
//       </svg>
//     </div>
//   </section>
// );

  
export default Hero;