import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, LogIn } from 'lucide-react';

const WelcomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-700 flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-xl p-8 text-center">
        <h1 className="text-3xl font-bold text-blue-900 mb-6">Welcome to Activated Human!</h1>
        <div className="text-gray-600 mb-8">
          <p className="mb-4">Thank you for subscribing to our service.</p>
          <p className="mb-4">Please check your email for further instructions on how to get started.</p>
          <div className="flex items-center justify-center text-blue-600 mb-4">
            <Mail className="w-5 h-5 mr-2" />
            <span>Check your inbox</span>
          </div>
        </div>
        <Link 
          to="/login" 
          className="inline-flex items-center bg-blue-600 text-white font-semibold py-3 px-6 rounded-full hover:bg-blue-700 transition duration-300"
        >
          <LogIn className="w-5 h-5 mr-2" />
          Go to Login
        </Link>
        <p className="mt-6 text-sm text-gray-500">
          Don't see the email? Check your spam folder or <a href="/contact" className="text-blue-600 hover:underline">contact support</a>.
        </p>
      </div>
    </div>
  );
};

export default WelcomePage;
