import React, { useState } from 'react';
import axios from 'axios';

// import apiClient from './apiClient';


// const EmailSignup = () => {
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');
//   const [error, setError] = useState('');

//   const validateEmail = (email) => {
//     const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
//     setMessage('');

//     if (!validateEmail(email)) {
//       setError('Please enter a valid email address.');
//       return;
//     }

//     try {
//       const response = await axios.post('/email-signup', { email });
//       setMessage('Thank you for signing up!');
//       setEmail('');
//     } catch (error) {
//       setError('An error occurred. Please try again.');
//     }
//   };

//   return (
//     <section className="py-16 bg-gray-100">
//       <div className="container mx-auto px-4 sm:px-6 md:px-8">
//         <h2 className="text-3xl font-semibold mb-8 text-center">Sign up for updates</h2>
//         <form onSubmit={handleSubmit} className="max-w-md mx-auto">
//           <div className="flex">
//             <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               placeholder="Enter your email"
//               required
//               className="flex-grow px-4 py-2 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//             />
//             <button type="submit" className="bg-blue-600 text-white px-6 py-2 rounded-r-md hover:bg-blue-700 transition duration-300 ease-in-out">
//               Sign Up
//             </button>
//           </div>
//           {error && <p className="text-red-600 mt-2">{error}</p>}
//           {message && <p className="text-green-600 mt-2">{message}</p>}
//         </form>
//       </div>
//     </section>
//   );
// };

// const EmailSignup = () => {
//   const [email, setEmail] = React.useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle email signup logic here
//     console.log('Email submitted:', email);
//     setEmail('');
//   };

//   return (
//     <section className="py-16 px-4 sm:px-6 lg:px-8 bg-opacity-50 backdrop-blur-md bg-blue-900">
//       <div className="max-w-3xl mx-auto text-center">
//         <h2 className="text-3xl font-bold mb-8">Sign up for updates</h2>
//         <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4 justify-center">
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Enter your email"
//             className="flex-grow px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
//             required
//           />
//           <button type="submit" className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition duration-300">
//             Sign Up
//           </button>
//         </form>
//       </div>
//     </section>
//   );
// };

// const EmailSignup = () => {
//   const [email, setEmail] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle email signup logic here
//     console.log('Email submitted:', email);
//     setEmail('');
//   };

//   return (
//     <section className="py-16 bg-gradient-to-r from-purple-900 to-blue-900">
//       <div className="container mx-auto px-4">
//         <div className="max-w-3xl mx-auto text-center">
//           <h2 className="text-3xl font-bold mb-8 text-white">Stay Updated on AI-Powered Growth</h2>
//           <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4 justify-center">
//             <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               placeholder="Enter your email"
//               className="flex-grow px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-coral-500 text-gray-800"
//               required
//             />
//             <button type="submit" className="bg-coral-500 text-white px-6 py-2 rounded-full hover:bg-coral-600 transition duration-300">
//               Sign Up
//             </button>
//           </form>
//         </div>
//       </div>
//     </section>
//   );
// };

const EmailSignup = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle email signup logic here
    console.log('Email submitted:', email);
    setEmail('');
  };

  return (
    <section className="py-16 bg-gradient-to-r from-[#4c1d95] to-[#1e40af]">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8 text-white">Stay Updated on AI-Powered Growth</h2>
          <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4 justify-center">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="flex-grow px-4 py-3 rounded-full focus:outline-none focus:ring-2 focus:ring-[#8b5cf6] text-gray-800 text-lg"
              required
            />
            <button 
              type="submit" 
              className="bg-[#8b5cf6] text-white px-8 py-3 rounded-full hover:bg-[#9d6eff] transition duration-300 text-lg font-semibold"
            >
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};


export default EmailSignup;
