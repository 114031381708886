import React from "react";
import { Brain, Globe, Zap, Target } from 'lucide-react';



// const FeatureItem = ({ icon: Icon, title, description }) => (
//   <div className="flex flex-col items-center text-center">
//     <div className="bg-blue-100 p-3 rounded-full mb-4">
//       <Icon className="w-8 h-8 text-blue-600" />
//     </div>
//     <h3 className="text-xl font-semibold mb-2 text-blue-900">{title}</h3>
//     <p className="text-gray-600">{description}</p>
//   </div>
// );

// const Features = () => (
//   <section className="py-20 bg-white">
//     <div className="container mx-auto px-4">
//       <h2 className="text-4xl font-bold text-center mb-12 text-blue-900">Key Features</h2>
//       <div className="grid md:grid-cols-3 gap-12">
//         <FeatureItem
//           icon={Zap}
//           title="AI-Powered Insights"
//           description="Get personalized recommendations based on your behavior patterns and goals."
//         />
//         <FeatureItem
//           icon={Target}
//           title="Goal Tracking"
//           description="Set, monitor, and achieve your personal and professional objectives with ease."
//         />
//         <FeatureItem
//           icon={MessageSquare}
//           title="Interactive Learning"
//           description="Engage in dynamic language exercises tailored to your proficiency level."
//         />
//         <FeatureItem
//           icon={TrendingUp}
//           title="Progress Analytics"
//           description="Visualize your growth and identify areas for improvement."
//         />
//         {/* Add more FeatureItems as needed */}
//       </div>
//     </div>
//   </section>
// );




// const VisionSection = () => (
//     <section className="py-20 bg-gray-800">
//       <div className="container mx-auto px-4">
//         <h2 className="text-4xl font-bold text-center mb-12 text-white">Our Vision</h2>
//         <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
//           <VisionCard 
//             icon={Brain} 
//             title="Cognitive Enhancement" 
//             description="Boost your mental capabilities through AI-powered exercises and insights."
//           />
//           <VisionCard 
//             icon={Target} 
//             title="Personalized Growth" 
//             description="Achieve your unique goals with tailored strategies and progress tracking."
//           />
//           <VisionCard 
//             icon={Zap} 
//             title="Accelerated Learning" 
//             description="Master new skills and languages faster with personalized learning paths."
//           />
//           <VisionCard 
//             icon={Globe} 
//             title="Global Connectivity" 
//             description="Access to your unique lifestyle from anywhere in the world."
//           />
//         </div>
//       </div>
//     </section>
//   );
  
//   const VisionCard = ({ icon: Icon, title, description }) => (
//     <div className="bg-gray-700 p-6 rounded-lg text-center hover:bg-gray-600 transition duration-300">
//       <Icon className="w-12 h-12 text-coral-500 mx-auto mb-4" />
//       <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
//       <p className="text-gray-300">{description}</p>
//     </div>
//   );


const VisionSection = () => (
  <section className="py-20 bg-gray-900">
    <div className="container mx-auto px-4">
      <h2 className="text-5xl font-bold text-center mb-16 text-white">Our Vision</h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        <VisionCard 
          icon={Target} 
          title="Personalized Growth" 
          description="Achieve your unique goals with tailored strategies and progress tracking."
        />
        <VisionCard 
          icon={Brain} 
          title="Cognitive Enhancement" 
          description="Boost your mental capabilities through AI-powered exercises and insights."
        />
        <VisionCard 
          icon={Zap} 
          title="Accelerated Learning" 
          description="Master new skills and languages faster with personalized learning paths."
        />
        <VisionCard 
          icon={Globe} 
          title="Global Connectivity" 
          description="Access your unique lifestyle from anywhere in the world."
        />
      </div>
    </div>
  </section>
);


const VisionCard = ({ icon: Icon, title, description }) => (
  <div className="bg-gray-800 p-8 rounded-xl text-center hover:bg-gray-700 transition duration-300 transform hover:-translate-y-1 hover:shadow-lg">
    <div className="bg-indigo-900 w-20 h-20 mx-auto rounded-full flex items-center justify-center mb-6">
      <Icon className="w-12 h-12 text-indigo-400" />
    </div>
    <h3 className="text-2xl font-semibold mb-4 text-indigo-300">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

  
export default VisionSection;
