import React from 'react';
import { Mail } from 'lucide-react';


const Contact = () => (
  <section id="contact" className="py-20 px-4 sm:px-6 lg:px-8 bg-[#2a2a3c]">
    <div className="max-w-3xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-8 text-white">Get in Touch</h2>
      <p className="text-xl text-gray-300 mb-8">Have questions about our AI-powered tools or need support? We're here to help!</p>
      <a 
        href="mailto:hello@activatedhuman.earth" 
        className="inline-flex items-center bg-[#8b5cf6] text-white font-semibold py-3 px-8 rounded-full hover:bg-[#7c3aed] transition duration-300"
      >
        <Mail className="w-5 h-5 mr-2" />
        Contact Us
      </a>
    </div>
  </section>
);

export default Contact;
