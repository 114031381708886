import React from 'react';
import { Home, Info, Mail } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

// // // function Header() {
// // //     const location = useLocation();

// // //     return (
// // //       <header className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-6 shadow-lg">
// // //         <div className="container mx-auto px-4">
// // //           <div className="flex flex-col sm:flex-row justify-between items-center">
// // //             <div className="text-center sm:text-left mb-4 sm:mb-0">
// // //               <h1 className="text-4xl sm:text-5xl font-bold">Activated Human</h1>
// // //               <p className="text-xl sm:text-2xl mt-1 text-blue-100">AI-Powered Tools for Personal Growth</p>
// // //             </div>
// // //             <div className="flex space-x-4">
// // //               <Link to="/" className="btn btn-glass">
// // //                 <Home className="w-5 h-5 mr-2" />
// // //                 Home
// // //               </Link>
// // //               <Link to="#about" className="btn btn-glass">
// // //                 <Info className="w-5 h-5 mr-2" />
// // //                 About
// // //               </Link>
// // //               <Link to="#contact" className="btn btn-glass">
// // //                 <Mail className="w-5 h-5 mr-2" />
// // //                 Contact
// // //               </Link>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </header>
// // //     );
// // // }

// // const Header = () => (
// //   <header className="bg-blue-900 text-white py-4 sticky top-0 z-50">
// //     <div className="container mx-auto px-4 flex justify-between items-center">
// //       <img src="/path-to-your-logo.svg" alt="Activated Human Logo" className="h-8" />
// //       <nav className="hidden md:flex space-x-6">
// //         <a href="#" className="hover:text-blue-300 transition duration-300">Home</a>
// //         <a href="#" className="hover:text-blue-300 transition duration-300">Products</a>
// //         <a href="#" className="hover:text-blue-300 transition duration-300">About</a>
// //         <a href="#" className="hover:text-blue-300 transition duration-300">Contact</a>
// //       </nav>
// //       <div className="flex space-x-4">
// //         <button className="bg-blue-700 hover:bg-blue-600 text-white px-4 py-2 rounded-full transition duration-300">Sign up</button>
// //         <button className="bg-white text-blue-900 px-4 py-2 rounded-full hover:bg-blue-100 transition duration-300">Get a demo</button>
// //       </div>
// //     </div>
// //   </header>
// // );

// function Header() {
//   const location = useLocation();

//   return (
//     <header className="bg-gradient-to-r from-blue-600 to-purple-700 text-white py-4 shadow-lg sticky top-0 z-50">
//       <div className="container mx-auto px-4">
//         <div className="flex flex-col sm:flex-row justify-between items-center">
//           <div className="text-center sm:text-left mb-4 sm:mb-0">
//             <h1 className="text-3xl sm:text-4xl font-bold">Activated Human</h1>
//             <p className="text-sm sm:text-lg mt-1 text-blue-100">AI-Powered Tools for Personal Growth</p>
//           </div>
//           <nav className="flex items-center space-x-2 sm:space-x-4">
//             <Link to="/" className="btn btn-glass">
//               <Home className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
//               <span className="hidden sm:inline">Home</span>
//             </Link>
//             <Link to="/products" className="btn btn-glass">
//               <Info className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
//               <span className="hidden sm:inline">Products</span>
//             </Link>
//             <Link to="/about" className="btn btn-glass">
//               <Info className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
//               <span className="hidden sm:inline">About</span>
//             </Link>
//             <Link to="/contact" className="btn btn-glass">
//               <Mail className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
//               <span className="hidden sm:inline">Contact</span>
//             </Link>
//           </nav>
//         </div>
//       </div>
//     </header>
//   );
// }

function Header() {
  const location = useLocation();


  const handleProductsClick = () => {
    // If the current page is the home page, scroll to the #products section
    if (location.pathname === '/') {
      const section = document.getElementById('products');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <header className="bg-gradient-to-r from-blue-600 to-purple-700 text-white py-4 shadow-lg sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-center sm:text-left mb-4 sm:mb-0">
            <h1 className="text-3xl sm:text-4xl font-bold">Activated Human</h1>
            <p className="text-sm sm:text-lg mt-1 text-blue-100">AI-Powered Tools for Personal Growth</p>
          </div>
          <nav className="flex items-center space-x-6 mb-4 sm:mb-0">
            <Link to="/" className="hover:text-blue-200 transition duration-300">Home</Link>
            <Link to="/products" onClick={handleProductsClick} className="hover:text-blue-200 transition duration-300">Products</Link>
            <Link to="/about" className="hover:text-blue-200 transition duration-300">About</Link>
            <Link to="/contact" className="hover:text-blue-200 transition duration-300">Contact</Link>
          </nav>
          <div className="flex space-x-4">
            <button className="bg-white text-blue-600 px-4 py-2 rounded-full hover:bg-blue-100 transition duration-300 text-sm font-semibold">Sign up</button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-400 transition duration-300 text-sm font-semibold">Get a demo</button>
          </div>
        </div>
      </div>
    </header>
  );
}



export default Header;